import React, { Component } from 'react'
import { Link } from 'gatsby'
import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'
import { connect } from 'react-redux'
import Title from '../components/page-title'
import Copy from '../components/page-copy'
import Row from '../components/page-content-row'
import { theme } from '../util/style'
import PageCtaFeatureList from '../components/page-cta-feature-list'
import { setLightboxForm } from '../actions'
import PageCtaFeature from '../components/page-cta-feature'
import Image1 from '../images/etc/guide-subtropical-homes-green-b.jpg'
import Image2 from '../images/etc/guide-subtropical-homes-happyhaus-gallery-photo-st-lucia-1-BB-9 1.jpg'
import Image3 from '../images/etc/guide-subtropical-homes-plan-1.jpg'
import Image4 from '../images/etc/guide-subtropical-homes-port-1.jpg'

const InclusionImage1 = 'etc/guide-subtropical-homes-green-b.jpg'

const ogImages = [`https://happyhaus.com.au${Image4}`]

const mapDispatchToProps = (dispatch) => ({})

class Page extends Component {
  constructor(props) {
    super(props)
    this._handleRequestClick = this._handleRequestClick.bind(this)
  }

  _handleRequestClick(e, form) {
    e.preventDefault()
    const { dispatch } = this.props

    switch (form) {
      case 'guide-subtropical-homes':
        dispatch(
          setLightboxForm({
            category: 'Subtropical Homes',
            title: 'Design Series: Subtropical Homes',
            redirect: '/guide-subtropical-homes-thank-you',
          })
        )
        break
    }
  }

  render() {
    const CtaFeatures = [
      {
        title: null,
        image: InclusionImage1,
        buttonLabel: 'Download our guide',
        bgColor: theme.colors.green2,
        fgColor: theme.colors.green3,
        style: 'cols',
        content: (
          <PageCtaFeatureList sepColor="#7FA25A">
            <li>
              <strong>Our guide has expert tips to help you</strong>
            </li>
            <li>Understand the considerations for living in the subtropics</li>
            <li>Apply simple design principles to your land and planning</li>
            <li>
              Explore essentially passive techniques suitable for our climate{' '}
            </li>
            <li>Know what to discuss when briefing your design team. </li>
          </PageCtaFeatureList>
        ),
        onClick: (e) => {
          this._handleRequestClick(e, 'guide-subtropical-homes')
        },
      },
    ]
    return (
      <>
        <SEO
          title="Design Series: Subtropical Homes"
          description="While design for the subtropics is a large topic — with many areas for consideration — our guide explores some basic principles that you can apply to home design that will help promote and enhance the joy of living in the subtropics."
          images={ogImages}
        />{' '}
        <Row>
          <Flex flexWrap="wrap" flexDirection={['column-reverse', 'row']}>
            <Box width={[1, 6 / 12]} px={2}>
              <Title>Design Series: Subtropical Homes</Title>
            </Box>
            <Box width={[1, 6 / 12]} px={2}>
              <Copy align="left">
                <p>
                  While design for the subtropics is a large topic — with many
                  areas for consideration — our guide explores some basic
                  principles that you can apply to home design that will help
                  promote and enhance the joy of living in the subtropics.
                </p>
              </Copy>
            </Box>
          </Flex>
        </Row>
        <Row>
          <PageCtaFeature features={CtaFeatures} />
        </Row>
        <Row>
          <Flex flexWrap={['wrap', 'nowrap']}>
            <Box width={[1, 6 / 12]} px={2} pr={[0, 0]}>
              <Title>
                Brian Donovan, Architect and Happy Haus Founding Partner
              </Title>
            </Box>
            <Box width={[1, 6 / 12]} px={2} mr={[0, 0]}>
              <Copy align="left">
                <p>
                  “We all have an idea in our mind of what makes a subtropical
                  house. The traditional Queenslander is recognised as a
                  wonderful example of a building of its time and type designed
                  in a response to and in sympathy with, the environment.
                </p>
                <p>
                  Features such as covered veranda spaces, window hoods and
                  latticed edges protect the core of a Queenslander from the
                  extremes of the climate and create a filtered transition of
                  dappled light and shade aimed at maximising comfort.
                </p>
                <p>
                  In many instances, this response to climate and care for
                  comfort has been lost in the mainstream homes of today —
                  primarily through a lack of awareness of the building siting,
                  plan arrangements, construction techniques and material
                  selection.”
                </p>
                <p>
                  <img src={Image4} ratio={1 / 1.5} />
                </p>
                <p>
                  The first instalment in our new design series culminates in a
                  guide to Subtropical Homes based on discussions with Architect
                  and Happy Haus Founding Partner, Brian Donovan about designing
                  and living in the Subtropics.
                </p>
                <p>
                  <a
                    onClick={(e) => {
                      e.preventDefault()
                      this._handleRequestClick(e, 'guide-subtropical-homes')
                    }}
                  >
                    <strong>Download our guide</strong>
                  </a>
                </p>
              </Copy>{' '}
            </Box>
          </Flex>
        </Row>
        <p>
          <img src={Image3} ratio={1 / 1.5} />
        </p>
        <Row>
          <Flex flexWrap={['wrap', 'nowrap']}>
            <Box width={[1, 6 / 12]} px={2} pr={[0, 0]}></Box>
            <Box width={[1, 6 / 12]} px={2} mr={[0, 0]}>
              <Copy align="left">
                <p>
                  From the guide: this section diagram explores how spaces
                  relating to each other can create both social and ventilation
                  opportunities.
                </p>
                <p>
                  <a
                    onClick={(e) => {
                      e.preventDefault()
                      this._handleRequestClick(e, 'guide-subtropical-homes')
                    }}
                  >
                    <strong>Download our guide</strong>
                  </a>
                </p>
              </Copy>{' '}
            </Box>
          </Flex>
        </Row>
        <p>
          <img src={Image2} ratio={1 / 1.5} />
        </p>
      </>
    )
  }
}

export default connect(mapDispatchToProps)(Page)
